import {GeneralStatusEnum} from "../enums/GeneralStatusEnum";
import {UserRoleEnum} from "../enums/UserRoleEnum";
import {IApiUser} from "../services/nestjs-base-api/types";
import {CreditStatusEnum} from "../enums/CreditStatusEnum";

export class Utils {
    public static readableGeneralStatus(status: GeneralStatusEnum) {
        switch (status) {
            case GeneralStatusEnum.Active:
                return "Active";
            case GeneralStatusEnum.Suspended:
                return "Suspended";
            case GeneralStatusEnum.Deleted:
                return "Deleted";
            default:
                return status;
        }
    }

    public static readableCreditStatus(status: CreditStatusEnum) {
        switch (status) {
            case CreditStatusEnum.Active:
                return "Active";
            case CreditStatusEnum.Expired:
                return "Expired";
            case CreditStatusEnum.Exhausted:
                return "Exhausted";
            default:
                return status;
        }
    }

    public static readableUserRole(role: UserRoleEnum) {
        switch (role) {
            case UserRoleEnum.OrganisationAdmin:
                return "Organisation administrator";
            case UserRoleEnum.OrganisationViewer:
                return "Organisation viewer";
            default:
                return role;
        }
    }

    public static userIsSuperAdmin(user: IApiUser) {
        const roles = JSON.parse(user.roles);

        return !!roles.includes(UserRoleEnum.SuperAdmin);
    }

    public static limitAndRound(value: number, min: number, max: number, decimals: number) {
        if (value) {
            const limitedValue = (value < min) ? min : (value > max) ? max : value;

            return limitedValue.toFixed(decimals);
        } else if (value === 0) {
            const limitedValue = 0;

            return limitedValue.toFixed(decimals);
        } else {
            return '-';
        }
    }

    public static getSubscriptionPricingTableId() {
        if (this.isProdMode()) {
            return 'prctbl_1QQK6XLoBJ5kLc1MAHlzwNnl';
        }

        return 'prctbl_1QJ3z9LoBJ5kLc1MwxQ2Ddcw';
    }

    public static getAddonPricingTableId() {
        if (this.isProdMode()) {
            return 'prctbl_1QQK8iLoBJ5kLc1M6noDwDOx';
        }

        return 'prctbl_1QFXe8LoBJ5kLc1MWbGpZZk9';
    }

    public static getPublishableKey() {
        if (this.isProdMode()) {
            return 'pk_live_51OrYG0LoBJ5kLc1MKHvDWQXTZlf57POuEQVh0Kk4nLzEpQmf817v15ZyG2HT9ifnG81SitqWYcKsOUoYGNV9GUKn00AH2vUzN5';
        }

        return 'pk_test_51OrYG0LoBJ5kLc1MiAUSzVTunEiY2YF1OiVbJpcog1Az4Rpi7PPFhiyjdm3ECdAzQ7aUt7OsmXJ9z6t02JNbUpe100YCY5eaaE';
    }

    public static isProdMode = (): boolean => {
        return process.env.REACT_APP_ENV === 'prod';
    }
}
