import React, {useCallback, useMemo, useState} from "react";
import {IOrganisation} from "../../services/nestjs-base-api/types";
import {FormattedMessage} from "react-intl";
import {Button, Modal, Table} from "antd";
import {EditOutlined} from "@ant-design/icons";
import AdminOrganisationAccountCreditUpdate from "./AdminOrganisationAccountCreditUpdate";

interface IProps {
    organisation: IOrganisation;
    refresh: () => void;
}

const AdminOrganisationAccount: React.FunctionComponent<IProps> = (props) => {
    const [credit, setCredit] = useState<any>(undefined);

    const organisationInvoices = useMemo(() => {
        if (props.organisation?.organisationInvoicesDto) {
            return JSON.parse(props.organisation.organisationInvoicesDto);
        }

        return null;
    }, [props.organisation]);

    const invoicesData = useMemo(() => {
        const returnValue = [];

        if (organisationInvoices) {
            for (const orgInvoices of organisationInvoices.invoices) {
                returnValue.push({
                    date: orgInvoices.specification.date,
                    amount: orgInvoices.specification.amount,
                    currency: orgInvoices.specification.currency,
                    service: orgInvoices.specification.description,
                    status: orgInvoices.specification.status,
                });
            }
        }

        return returnValue;
    }, [organisationInvoices]);

    const invoicesColumns = useMemo(() => {
        return [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: ((data: any) => {
                    const date = new Date(data * 1000);
                    const toOutput = date.toString();
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: ((data: any, record: any) => {
                    const value = data/100;
                    const toOutput = `$${value} ${record.currency.toUpperCase()}`;
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Service',
                dataIndex: 'service',
                key: 'service',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: ((data: any) => {
                    const toOutput = data.toUpperCase();
                    const useStyle = data === 'succeeded' ? {backgroundColor: 'green', color: 'white', textAlign: 'center'} : {textAlign: 'center'};
                    // @ts-ignore
                    return <div style={useStyle}>{toOutput}</div>
                })
            },
        ]
    }, []);

    const organisationCredits = useMemo(() => {
        if (props.organisation?.organisationCreditsDto) {
            return JSON.parse(props.organisation.organisationCreditsDto);
        }

        return null;
    }, [props.organisation]);

    const creditsData = useMemo(() => {
        const returnValue = [];

        if (organisationCredits) {
            for (const orgCredits of organisationCredits.credits) {
                returnValue.push({
                    paymentIdent: orgCredits.specification.paymentIdent,
                    service: orgCredits.specification.name,
                    used: orgCredits.specification.used,
                    available: orgCredits.specification.available,
                    expiry: orgCredits.specification.expires,
                    total: orgCredits.specification.total,
                    status: orgCredits.specification.status,
                });
            }
        }

        return returnValue;
    }, [organisationCredits]);

    const onEditCreditClicked = useCallback((row: any) => {
        setCredit(row);
    }, []);

    const onUpdateCreditClose = useCallback(() => {
        setCredit(undefined);
        props.refresh();
    }, [props]);

    const creditsColumns = useMemo(() => {
        return [
            {
                title: 'Service',
                dataIndex: 'service',
                key: 'service',
            },
            {
                title: 'Available',
                dataIndex: 'available',
                key: 'available',
            },
            {
                title: 'Used',
                dataIndex: 'used',
                key: 'used',
            },
            {
                title: 'Expires',
                dataIndex: 'expiry',
                key: 'expiry',
                render: ((data: any) => {
                    let toOutput = '-';

                    if (data) {
                        toOutput = data.toString();
                    }

                    return <>{toOutput}</>
                }),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: ((data: any) => {
                    const toOutput = data.toUpperCase();
                    const useStyle = data === 'ACTIVE' ? {backgroundColor: 'green', color: 'white', textAlign: 'center'} : {textAlign: 'center'};
                    // @ts-ignore
                    return <div style={useStyle}>{toOutput}</div>
                })
            },
            {
                title: 'Actions',
                key: 'actions',
                render: ((_: string, row: any) => {
                    return (
                        <Button
                            onClick={() => onEditCreditClicked(row)}
                            icon={<EditOutlined />}
                        >
                        </Button>
                    );
                })
            },
        ]
    }, [onEditCreditClicked]);

    const organisationSubscriptions = useMemo(() => {
        if (props.organisation?.organisationSubscriptionsDto) {
            return JSON.parse(props.organisation.organisationSubscriptionsDto);
        }

        return null;
    }, [props.organisation]);

    const subscriptionData = useMemo(() => {
        const returnValue = [];

        if (organisationSubscriptions) {
            for (const orgSubscription of organisationSubscriptions.subscriptions) {
                returnValue.push({
                    subscription: orgSubscription.specification.description,
                    started: orgSubscription.specification.date,
                    amount: orgSubscription.specification.amount,
                    currency: orgSubscription.specification.currency,
                    status: orgSubscription.specification.status,
                    expires: orgSubscription.specification.expires,
                });
            }
        }

        return returnValue;
    }, [organisationSubscriptions]);

    const subscriptionColumns = useMemo(() => {
        return [
            {
                title: 'Subscription',
                dataIndex: 'subscription',
                key: 'subscription',
            },
            {
                title: 'Started',
                dataIndex: 'started',
                key: 'started',
                render: ((data: any) => {
                    const date = new Date(data * 1000);
                    const toOutput = date.toString();
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: ((data: any, record: any) => {
                    const value = data/100;
                    const toOutput = `$${value} ${record.currency.toUpperCase()}`;
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Expires',
                dataIndex: 'expires',
                key: 'expires',
                render: ((data: any) => {
                    let toOutput = '-';
                    if (data) {
                        const date = new Date(data * 1000);
                        toOutput = date.toString();
                    }
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: ((data: any) => {
                    const toOutput = data.toUpperCase();
                    const useStyle = data === 'active' ? {backgroundColor: 'green', color: 'white', textAlign: 'center'} : {textAlign: 'center'};
                    // @ts-ignore
                    return <div style={useStyle}>{toOutput}</div>
                })
            },
        ]
    }, []);

    return (
        <div className="project-tab">
            {props.organisation && (
                <>
                    <h3><FormattedMessage id={'section.pricingName'}/></h3>
                    <Table dataSource={subscriptionData} columns={subscriptionColumns}/>

                    <h3><FormattedMessage id={'account.accountCredits.title'}/></h3>
                    <Table dataSource={creditsData} columns={creditsColumns}/>

                    <h3><FormattedMessage id={'account.invoiceHistory.title'}/></h3>
                    <Table dataSource={invoicesData} columns={invoicesColumns}/>
                </>
            )}
            <Modal
                open={!!credit}
                onCancel={onUpdateCreditClose}
                centered
                footer={null}
            >
                <AdminOrganisationAccountCreditUpdate
                    organisationId={props.organisation.id}
                    accountCredit={credit}
                    onClose={onUpdateCreditClose}
                />
            </Modal>
        </div>
    );
}

export default AdminOrganisationAccount;
