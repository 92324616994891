import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Breadcrumb, Card, Col, Layout, Modal, Row, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";
import {
    useGetGuidesProgressQuery,
    useUpdateGuidesProgressMutation
} from "../services/nestjs-base-api";
import {IGuidesProgress} from "../services/nestjs-base-api/types";
import {useFeatureActive} from "../hooks/useFeatureActive";
import {FeatureTypeEnum} from "../enums/FeatureTypeEnum";
import {UpgradeModal} from "../components/UpgradeModal";
import {useParams} from "react-router-dom";
import ReactPlayer from 'react-player/youtube';
import {SelectOutlined} from "@ant-design/icons";
import {OnProgressProps} from "react-player/base";
import {GuideTypeEnum} from "../enums/GuideTypeEnum";

const { Content } = Layout;

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const GuidesPage: React.FunctionComponent = () => {
    const { bookNumber } = useParams();
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [accessToGuidesActive] = useFeatureActive(FeatureTypeEnum.AccessToGuides);
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
    const playerRef = useRef<ReactPlayer>(null);

    const {data: guidesProgressData, error: guidesProgressError} = useGetGuidesProgressQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});
    const [updateGuidesProgress, updateGuideProgressResult] = useUpdateGuidesProgressMutation();

    useEffect(() => {
        if (guidesProgressError) {
            if ('status' in guidesProgressError) {
                if (guidesProgressError.status === 401) {
                    logout();
                }
            }
        }
    }, [guidesProgressError, logout]);

    const guidesProgress = useMemo(() => {
        return guidesProgressData ? guidesProgressData as unknown as IGuidesProgress : undefined;
    }, [guidesProgressData]);

    const onUpgradeClose = useCallback(() => {
        setShowUpgradeModal(false);
    }, []);

    useEffect(() => {
        if (!accessToGuidesActive) {
            setShowUpgradeModal(true);
        }
    }, [accessToGuidesActive]);

    const videoUrl = useMemo(() => {
        switch (bookNumber) {
            case '1':
                return 'https://youtu.be/XMTR6eCQttQ';
            case '2':
                return 'https://youtu.be/AOAPlWtYDVw';
            case '3':
                return 'https://youtu.be/X2jBP1hq3Gk';
            case '4':
                return 'https://youtu.be/slOgoqBuRdA';
            case '5':
                return 'https://youtu.be/aCOMIvjvEY4';
            case '6':
            default:
                return 'https://youtu.be/vR2TFrwhwQc';
        }
    }, [bookNumber]);

    const workbookUrl = useMemo(() => {
        switch (bookNumber) {
            case '1':
                return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/workbook-1.pdf';
            case '2':
                return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/workbook-2.pdf';
            case '3':
                return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/workbook-3.pdf';
            case '4':
                return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/workbook-4.pdf';
            case '5':
                return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/workbook-5.pdf';
            case '6':
            default:
                return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/workbook-6.pdf';
        }
    }, [bookNumber]);

    const guideType = useMemo(() => {
        switch (bookNumber) {
            case '1':
                return GuideTypeEnum.Workbook1;
            case '2':
                return GuideTypeEnum.Workbook2;
            case '3':
                return GuideTypeEnum.Workbook3;
            case '4':
                return GuideTypeEnum.Workbook4;
            case '5':
                return GuideTypeEnum.Workbook5;
            case '6':
            default:
                return GuideTypeEnum.Workbook6;
        }
    }, [bookNumber]);

    const workbookProgress = (guideType: GuideTypeEnum) => {
        const guide = guidesProgress?.guides.find((elem) => elem.guide === guideType);

        if (guide) {
            return guide.progress;
        }

        return 0;
    };

    const onProgress = useCallback((state: OnProgressProps) => {
        if (state.played === 0 && state.playedSeconds === 0 && state.loaded === 0 && state.loadedSeconds === 0) {
            playerRef.current?.seekTo(workbookProgress(guideType), 'fraction');
        } else {
            const guidesProgressCopy = JSON.parse(JSON.stringify(guidesProgress));
            const interested = guidesProgressCopy.guides.find((elem: { guide: GuideTypeEnum; }) => elem.guide === guideType);

            if (interested) {
                interested.progress = state.played;

                updateGuidesProgress({jwtToken: user.jwtToken, payload: guidesProgressCopy});
            }
        }
    }, [guidesProgress, guideType, updateGuidesProgress, user, workbookProgress]);

    useEffect(() => {

    }, []);

    const guidesSection = useMemo(() => {
        if (accessToGuidesActive) {
            return (
                <>
                    <Row gutter={16} align='middle'>
                        <Col span={20}>
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    className='react-player'
                                    width='100%'
                                    height='100%'
                                    url={videoUrl!}
                                    onProgress={onProgress}
                                    controls={true}
                                    ref={playerRef}
                                />
                            </div>
                        </Col>
                        <Col span={4}>
                            <Card
                                style={{paddingTop: '1rem'}}
                                hoverable
                                cover={<img alt="example" src="/pdf-file.png"/>}
                                onClick={() => window.open(workbookUrl)}
                            >
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    Click to view <SelectOutlined rotate={90} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </>
            );
        }

        return null;
    }, [accessToGuidesActive, videoUrl, workbookUrl, onProgress]);

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation ? user.organisation.name : 'Platform'}</Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.guidesName'} /></Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.workbookName'} values={{number: bookNumber}}/></Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2><FormattedMessage id={'section.workbookName'} values={{number: bookNumber}}/></h2>
                {guidesSection}
            </div>
            <Modal
                open={showUpgradeModal}
                onCancel={onUpgradeClose}
                centered
                footer={null}
                width={'60rem'}
                className='upgrade-modal'
            >
                <UpgradeModal
                    onclose={onUpgradeClose}
                />
            </Modal>
        </Content>
    );
}

export default GuidesPage;