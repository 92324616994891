import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Button, Layout, Modal, Row, Table, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {
    useCheckAccountMutation,
    useGetAccountDetailsQuery,
    useGetStripeCustomerPortalSessionQuery,
    useGetStripeCustomerSessionClientSecretQuery,
} from "../services/nestjs-base-api";
import {
    IOrganisation,
    IStripeCustomerPortalSession,
    IStripeCustomerSessionClientSecret
} from "../services/nestjs-base-api/types";
import {Utils} from "../utils/utils";
import {PlanLevelEnum} from "../enums/PlanLevelEnum";

const { Content } = Layout;

const AccountPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
    const {data, error} = useGetStripeCustomerPortalSessionQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});
    const {data: clientSecretData, error: clientSecretError} = useGetStripeCustomerSessionClientSecretQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});
    const navigate = useNavigate();
    const {data: accountDetailsData, refetch: refetchAccountDetails, error: accountDetailsError} = useGetAccountDetailsQuery({jwtToken: user.jwtToken});
    const [checkAccount, checkAccountResult] = useCheckAccountMutation();

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const stripeCustomerPortalSession = useMemo(() => {
        return data ? data as unknown as IStripeCustomerPortalSession : undefined;
    }, [data]);

    const sessionParameter = useMemo(() => {
        return searchParams.has('sess');
    }, [searchParams]);

    const onClosePaymentSuccess = useCallback(() => {
        setShowPaymentSuccess(false);
    }, []);

    useEffect(() => {
        if (sessionParameter) {
            setShowPaymentSuccess(true);
            checkAccount({jwtToken: user.jwtToken});
        }
    }, [sessionParameter, user, checkAccount]);

    const stripeSessionClientSecret = useMemo(() => {
        return clientSecretData ? clientSecretData as unknown as IStripeCustomerSessionClientSecret : undefined;
    }, [clientSecretData]);

    const loadedOrganisation = useMemo(() => {
        if (accountDetailsData) {
            return accountDetailsData ? accountDetailsData as unknown as IOrganisation : undefined;
        }
    }, [accountDetailsData]);

    const organisationInvoices = useMemo(() => {
        if (loadedOrganisation?.organisationInvoicesDto) {
            return JSON.parse(loadedOrganisation.organisationInvoicesDto);
        }

        return null;
    }, [loadedOrganisation]);

    const invoicesData = useMemo(() => {
        const returnValue = [];

        if (organisationInvoices) {
            for (const orgInvoices of organisationInvoices.invoices) {
                returnValue.push({
                    date: orgInvoices.specification.date,
                    amount: orgInvoices.specification.amount,
                    currency: orgInvoices.specification.currency,
                    service: orgInvoices.specification.description,
                    status: orgInvoices.specification.status,
                });
            }
        }

        return returnValue;
    }, [organisationInvoices]);

    const invoicesColumns = useMemo(() => {
        return [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: ((data: any) => {
                    const date = new Date(data * 1000);
                    const toOutput = date.toString();
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: ((data: any, record: any) => {
                    const value = data/100;
                    const toOutput = `$${value} ${record.currency.toUpperCase()}`;
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Service',
                dataIndex: 'service',
                key: 'service',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: ((data: any) => {
                    const toOutput = data.toUpperCase();
                    const useStyle = data === 'succeeded' ? {backgroundColor: 'green', color: 'white', textAlign: 'center'} : {textAlign: 'center'};
                    // @ts-ignore
                    return <div style={useStyle}>{toOutput}</div>
                })
            },
        ]
    }, []);

    const organisationCredits = useMemo(() => {
        if (loadedOrganisation?.organisationCreditsDto) {
            return JSON.parse(loadedOrganisation.organisationCreditsDto);
        }

        return null;
    }, [loadedOrganisation]);

    const creditsData = useMemo(() => {
        const returnValue = [];

        if (organisationCredits) {
            for (const orgCredits of organisationCredits.credits) {
                returnValue.push({
                    service: orgCredits.specification.name,
                    used: orgCredits.specification.used,
                    available: orgCredits.specification.available,
                    expiry: orgCredits.specification.expires,
                    status: orgCredits.specification.status,
                });
            }
        }

        return returnValue;
    }, [organisationCredits]);

    const creditsColumns = useMemo(() => {
        return [
            {
                title: 'Service',
                dataIndex: 'service',
                key: 'service',
            },
            {
                title: 'Available',
                dataIndex: 'available',
                key: 'available',
            },
            {
                title: 'Used',
                dataIndex: 'used',
                key: 'used',
            },
            {
                title: 'Expires',
                dataIndex: 'expiry',
                key: 'expiry',
                render: ((data: any) => {
                    let toOutput = '-';

                    if (data) {
                        toOutput = data.toString();
                    }

                    return <>{toOutput}</>
                }),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: ((data: any) => {
                    const toOutput = data.toUpperCase();
                    const useStyle = data === 'ACTIVE' ? {backgroundColor: 'green', color: 'white', textAlign: 'center'} : {textAlign: 'center'};
                    // @ts-ignore
                    return <div style={useStyle}>{toOutput}</div>
                })
            },
        ]
    }, []);

    const organisationSubscriptions = useMemo(() => {
        if (loadedOrganisation?.organisationSubscriptionsDto) {
            return JSON.parse(loadedOrganisation.organisationSubscriptionsDto);
        }

        return null;
    }, [loadedOrganisation]);

    const subscriptionData = useMemo(() => {
        const returnValue = [];

        if (organisationSubscriptions) {
            for (const orgSubscription of organisationSubscriptions.subscriptions) {
                returnValue.push({
                    subscription: orgSubscription.specification.description,
                    started: orgSubscription.specification.date,
                    amount: orgSubscription.specification.amount,
                    currency: orgSubscription.specification.currency,
                    status: orgSubscription.specification.status,
                    expires: orgSubscription.specification.expires,
                });
            }
        }

        return returnValue;
    }, [organisationSubscriptions]);

    const subscriptionColumns = useMemo(() => {
        return [
            {
                title: 'Subscription',
                dataIndex: 'subscription',
                key: 'subscription',
            },
            {
                title: 'Started',
                dataIndex: 'started',
                key: 'started',
                render: ((data: any) => {
                    const date = new Date(data * 1000);
                    const toOutput = date.toString();
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: ((data: any, record: any) => {
                    const value = data/100;
                    const toOutput = `$${value} ${record.currency.toUpperCase()}`;
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Expires',
                dataIndex: 'expires',
                key: 'expires',
                render: ((data: any) => {
                    let toOutput = '-';
                    if (data) {
                        const date = new Date(data * 1000);
                        toOutput = date.toString();
                    }
                    return <>{toOutput}</>
                })
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: ((data: any) => {
                    const toOutput = data.toUpperCase();
                    const useStyle = data === 'active' ? {backgroundColor: 'green', color: 'white', textAlign: 'center'} : {textAlign: 'center'};
                    // @ts-ignore
                    return <div style={useStyle}>{toOutput}</div>
                })
            },
        ]
    }, []);

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation ? user.organisation.name : 'Platform'}</Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.accountName'} /></Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2><FormattedMessage id={'section.accountName'}/></h2>
                <h3><FormattedMessage id={'section.pricingName'}/></h3>
                <Table dataSource={subscriptionData} columns={subscriptionColumns}/>
                <Row>
                    {stripeCustomerPortalSession && (
                        <Link to={stripeCustomerPortalSession.url}>
                            <FormattedMessage id={'account.manageSubscriptionsButton'}/>
                        </Link>
                    )}
                </Row>

                <h3><FormattedMessage id={'account.accountCredits.title'}/></h3>
                <Table dataSource={creditsData} columns={creditsColumns}/>
                <Button
                    type='primary'
                    onClick={() => navigate("/dashboard/addons", {replace: false})}
                >
                    <FormattedMessage id={'account.accountCredits.buyButton'}/>
                </Button>

                <h3><FormattedMessage id={'account.invoiceHistory.title'}/></h3>
                <Table dataSource={invoicesData} columns={invoicesColumns}/>

                {user.organisation.plan === PlanLevelEnum.Plan1 && (
                    <>
                        <h3><FormattedMessage id={'account.subscriptionUpgrade.title'}/></h3>
                        {stripeSessionClientSecret && (
                            <stripe-pricing-table className='stripe-pricing-table'
                                                  pricing-table-id={Utils.getSubscriptionPricingTableId()}
                                                  publishable-key={Utils.getPublishableKey()}
                                                  customer-session-client-secret={stripeSessionClientSecret.secret}
                            >
                            </stripe-pricing-table>
                        )}
                    </>
                )}

            </div>
            <Modal title={<FormattedMessage id={'payment.success.title'}/>} open={showPaymentSuccess}
                   onOk={onClosePaymentSuccess}>
                <p><FormattedMessage id={'payment.success.message'}/></p>
                <p><FormattedMessage id={'payment.success.logoutMessage'}/></p>
            </Modal>
        </Content>
    );
}

export default AccountPage;
