import React from "react";
import {useAuth} from "../hooks/useAuth";
import {Navigate} from "react-router-dom";
import {Utils} from "../utils/utils";

const HomePage: React.FunctionComponent = () => {
    const { user } = useAuth();

    if (user) {
        if (Utils.userIsSuperAdmin(user)) {
            return <Navigate to="/dashboard/administration" />;
        }
        return <Navigate to="/dashboard" />;
    } else {
        return <Navigate to="/sign-in" />;
    }
}

export default HomePage;
