import {Navigate, useOutlet, useNavigate, useLocation} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import React, {useCallback, useMemo, useState} from "react";
import {
    BookOutlined,
    DollarOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    LogoutOutlined, PictureOutlined,
    SettingOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme } from 'antd';
import {FormattedMessage} from "react-intl";
import {Utils} from "../utils/utils";

const { Header, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

export const ProtectedLayout = () => {
    const { user, logout } = useAuth();
    const outlet = useOutlet();
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = useCallback(() => {
        logout();
    }, [logout]);

    const items: MenuItem[] = useMemo(() => {
        const returnValue = [];

        if (Utils.userIsSuperAdmin(user)) {
            returnValue.push(getItem(<FormattedMessage id={'section.administrationName'}/>, 'administration', <SettingOutlined />));
        } else {
            returnValue.push(getItem(<FormattedMessage id={'section.dashboardName'}/>, 'dashboard', <HomeOutlined/>));
            returnValue.push(getItem(<FormattedMessage id={'section.booksName'}/>, 'books', <BookOutlined/>));
            returnValue.push(getItem(<FormattedMessage id={'section.imagesName'}/>, 'images', <PictureOutlined/>));
            returnValue.push(getItem(<FormattedMessage id={'section.accountName'}/>, 'account', <SettingOutlined/>));
            returnValue.push(getItem(<FormattedMessage id={'section.addonsName'}/>, 'addons', <DollarOutlined/>));
            returnValue.push(getItem(<FormattedMessage id={'section.guidesName'}/>, 'guides', <InfoCircleOutlined/>));
            returnValue.push(getItem(<FormattedMessage id={'section.organisationName'}/>, 'settings',
                <SettingOutlined/>));
        }

        return returnValue;
    }, [user]);

    const bottomItems: MenuItem[] = useMemo(() => {
        const returnValue = [];

        returnValue.push(getItem(<FormattedMessage id={'section.signoutName'} />, 'signout', <LogoutOutlined />));

        return returnValue;
    }, []);

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === "signout") {
            handleLogout();
        } else if (e.key === 'profile') {
            navigate("/dashboard/profile", { replace: true });
        } else if (e.key === 'settings') {
            navigate("/dashboard/settings", { replace: true });
        } else if (e.key === 'account') {
            navigate("/dashboard/account", { replace: true });
        } else if (e.key === 'subscriptions') {
            navigate("/dashboard/subscriptions", { replace: true });
        } else if (e.key === 'addons') {
            navigate("/dashboard/addons", { replace: true });
        } else if (e.key === 'guides') {
            navigate("/dashboard/guides", { replace: true });
        } else if (e.key === 'books') {
            navigate("/dashboard/books", { replace: true });
        } else if (e.key === 'images') {
            navigate("/dashboard/images", { replace: true });
        } else if (e.key === 'administration') {
            navigate("/dashboard/administration", { replace: true });
        } else if (e.key === 'dashboard') {
            navigate("/dashboard", { replace: true });
        }
    };

    const selectedKeys = useMemo(() => {
        const returnValue = [];

        if (location.pathname.indexOf('workbook') !== -1) {
            returnValue.push('guides');
        } else {
            switch (location.pathname) {
                case '/dashboard/profile':
                    returnValue.push('profile');
                    break;
                case '/dashboard/settings':
                    returnValue.push('settings');
                    break;
                case '/dashboard/account':
                    returnValue.push('account');
                    break;
                case '/dashboard/subscriptions':
                    returnValue.push('subscriptions');
                    break;
                case '/dashboard/addons':
                    returnValue.push('addons');
                    break;
                case '/dashboard/guides':
                    returnValue.push('guides');
                    break;
                case '/dashboard/books':
                    returnValue.push('books');
                    break;
                case '/dashboard/images':
                    returnValue.push('images');
                    break;
                case '/dashboard/administration':
                    returnValue.push('administration');
                    break;
                default:
                    returnValue.push('dashboard');
                    break;
            }
        }

        return returnValue;
    }, [location.pathname]);

    if (!user) {
        return <Navigate to="/sign-in" />;
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={250} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div style={{width: '100%', padding: '1.2rem'}}>
                    <img src='/logo192.png' alt='remember-press-logo'
                         style={{display: 'block', width: '5rem', margin: '0 auto'}}/>
                </div>
                <div>
                    <Menu
                        onClick={onClick}
                        theme="dark"
                        selectedKeys={selectedKeys}
                        mode="inline"
                        items={items}
                    />
                </div>
                <div>
                    <Menu
                        onClick={onClick}
                        style={{marginTop: "1rem"}}
                        theme="dark"
                        mode="inline"
                        items={bottomItems}
                        selectable={false}
                    />
                </div>
            </Sider>
            <Layout>
                <Header style={{padding: 0, background: colorBgContainer}}/>
                {outlet}
                <Footer style={{textAlign: 'center'}}>
                    RememberPress.com © {new Date().getFullYear()} by Syriant
                </Footer>
            </Layout>
        </Layout>
    );
};
