import React, {useCallback, useState} from "react";

import {ExclamationCircleOutlined, LockOutlined} from "@ant-design/icons";
import {useUpdatePasswordMutation} from "../services/nestjs-base-api";

import {Button, Form, Grid, Input, message, theme, Typography} from "antd";
import {useParams} from "react-router-dom";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const PasswordSetPage: React.FunctionComponent = () => {
    const { token } = useToken();
    const screens = useBreakpoint();
    const [updatePassword, updatePasswordResult] = useUpdatePasswordMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const [hideForm, setHideForm] = useState<boolean>(false);
    const { resetToken } = useParams();

    const onUpdateError = useCallback(() => {
        messageApi.open({
            type: 'error',
            content: 'Password set failed, the link has already been used or has expired, contact us for further assistance.',
            className: 'custom-class',
            style: {
                marginTop: '5vh',
                fontSize: '1.2rem'
            },
            duration: 4,
            icon: <ExclamationCircleOutlined style={{fontSize: '1.2rem', paddingBottom: '0.1rem'}} />
        });
    }, [messageApi]);

    const handleUpdatePassword = useCallback(async (values: any) => {
        const updateResponse = await updatePassword({token: resetToken!, password: values.password});

        if ('data' in updateResponse) {
            setHideForm(true);
        } else {
            onUpdateError();
        }
    }, [onUpdateError, resetToken, updatePassword]);

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px"
        },
        footer: {
            marginTop: token.marginLG,
            width: "100%",
            textAlign: "center",
        },
        forgotPassword: {
        },
        header: {
            marginBottom: token.marginXL,
            textAlign: "center",
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            height: screens.sm ? "85vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        }
    };

    return (
        <section style={styles.section}>
            {contextHolder}
            <div style={styles.container}>
                <div style={{display: hideForm ? 'none' : 'block'}}>
                    <div style={{
                        marginBottom: token.marginXL,
                        textAlign: "center",
                    }}>
                        <img src='/remember-press-logo.png' alt='remember-press-logo' style={{width: '20rem'}}/>
                        <Title style={styles.title}>Complete account setup</Title>
                        <Text style={styles.text}>
                            Enter a new password and submit to complete the setup of your account.
                        </Text>
                    </div>
                    <Form
                        name="normal_set"
                        onFinish={handleUpdatePassword}
                        layout="vertical"
                        requiredMark="optional"
                    >
                        <Form.Item
                            name="password"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password, at least 8 characters",
                                    min: 8
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined/>}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,

                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Password and confirm password don't match"));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined/>}
                                type="password"
                                placeholder="Confirm password"
                            />
                        </Form.Item>
                        <Form.Item style={{marginBottom: "0px"}}>
                            <Button block={true} type="primary" htmlType="submit"
                                    disabled={updatePasswordResult.isLoading}>
                                Complete setup
                            </Button>
                            <div style={{
                                marginTop: token.marginLG,
                                width: "100%",
                                textAlign: "center",
                            }}>
                                <Text style={styles.text}>Problems completing setup?</Text>{" "}
                                <Link href="/">Contact us for futher assistance</Link>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{display: hideForm ? 'block' : 'none'}}>
                    <div style={{
                        marginBottom: token.marginXL,
                        textAlign: "center",
                    }}>
                        <img src='/remember-press-logo.png' alt='remember-press-logo' style={{width: '20rem'}}/>
                        <Title style={styles.title}>Setup complete</Title>
                        <Text style={styles.text}>
                            Your new account is now ready. Head over to the <Link href="/sign-in">Sign-in page</Link> to
                            sign-in now!
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PasswordSetPage;
