import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Layout, Modal, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";
import {useFeatureActive} from "../hooks/useFeatureActive";
import {FeatureTypeEnum} from "../enums/FeatureTypeEnum";
import {UpgradeModal} from "../components/UpgradeModal";
import {useNavigate} from "react-router-dom";

const { Content } = Layout;

const GuidesPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [accessToImagesLibrary] = useFeatureActive(FeatureTypeEnum.AccessToImagesLibrary);
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const onUpgradeClose = useCallback(() => {
        setShowUpgradeModal(false);
    }, []);

    useEffect(() => {
        if (!accessToImagesLibrary) {
            setShowUpgradeModal(true);
        }
    }, [accessToImagesLibrary]);


    const imagesSection = useMemo(() => {
        if (accessToImagesLibrary) {
            return (
                <>

                </>
            );
        }

        return null;
    }, [accessToImagesLibrary, navigate])

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation ? user.organisation.name : 'Platform'}</Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.imagesName'} /></Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2><FormattedMessage id={'section.imagesName'} /></h2>
                {imagesSection}
            </div>
            <Modal
                open={showUpgradeModal}
                onCancel={onUpgradeClose}
                centered
                footer={null}
                width={'60rem'}
                className='upgrade-modal'
            >
                <UpgradeModal
                    onclose={onUpgradeClose}
                />
            </Modal>
        </Content>
    );
}

export default GuidesPage;