import React, {useMemo} from "react";
import {Button} from "antd";
import {LockOutlined} from "@ant-design/icons";
import {IStripeCustomerSessionClientSecret} from "../services/nestjs-base-api/types";
import {useGetStripeCustomerSessionClientSecretQuery} from "../services/nestjs-base-api";
import {useAuth} from "../hooks/useAuth";
import {Utils} from "../utils/utils";

interface IProps {
    onclose: () => void;
    showOk?: boolean;
}
export const UpgradeModal: React.FunctionComponent<IProps> = (props) => {
    const { user } = useAuth();
    const {data, error} = useGetStripeCustomerSessionClientSecretQuery({jwtToken: user.jwtToken});

    const stripeSessionClientSecret = useMemo(() => {
        return data ? data as unknown as IStripeCustomerSessionClientSecret : undefined;
    }, [data]);

    return (
        <div style={{minHeight: "66vh"}}>
            <h2><LockOutlined /> Upgrade to unlock</h2>
            <p>In order to unlock this feature, you need to upgrade your plan.</p>
            {stripeSessionClientSecret && (
                <stripe-pricing-table className='stripe-pricing-table'
                                      pricing-table-id={Utils.getSubscriptionPricingTableId()}
                                      publishable-key={Utils.getPublishableKey()}
                                      customer-session-client-secret={stripeSessionClientSecret.secret}
                >
                </stripe-pricing-table>
            )}
            {props.showOk && (
                <Button
                    type='primary'
                    onClick={props.onclose}
                >
                    OK
                </Button>
            )}
        </div>
    );
};