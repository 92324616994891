import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    EntityTag,
    IApiUser,
    IAuth,
    ICreateApiUser,
    ICreateOrganisationWithApiUser, IGuidesProgress,
    IOrganisation, IStripeCustomerPortalSession, IStripeCustomerSessionClientSecret, IUpdateAccountCredit,
    IUpdateApiUser,
} from "./types";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const api = createApi({
    tagTypes: [
        EntityTag.Organisation,
        EntityTag.GuidesProgress,
        EntityTag.AccountDetails,
    ],
    baseQuery: fetchBaseQuery({ baseUrl:  apiBaseUrl }),
    endpoints: (build) => ({
        login: build.mutation<IAuth, { email: string, password: number }>({
            query: (params) => ({
                url: `/auth/login`,
                method: 'POST',
                body: { username: params.email, password: params.password },
            }),
        }),
        resetPassword: build.mutation<void, { email: string }>({
            query: (params) => ({
                url: `/auth/reset-password`,
                method: 'PUT',
                body: { username: params.email },
            }),
        }),
        updatePassword: build.mutation<IApiUser, { token: string, password: string }>({
            query: (params) => ({
                url: `/auth/update-password`,
                method: 'PUT',
                body: { token: params.token, password: params.password },
            }),
        }),
        getProfile: build.mutation<IApiUser, { jwtToken: string }>({
            query: (params) => ({
                url: `/auth/profile`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getUsers: build.query<{data: IApiUser[]}, { jwtToken: string }>({
            query: (params) => ({
                url: `/users/api-users`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        updateUser: build.mutation<IApiUser, {jwtToken: string, payload: IUpdateApiUser}>({
            query: (params) => ({
                url: `/users/api-user`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createUser: build.mutation<IApiUser, {jwtToken: string, payload: ICreateApiUser}>({
            query: (params) => ({
                url: `/users/api-user`,
                method: 'POST',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createOrganisationWithUser: build.mutation<IApiUser, {payload: ICreateOrganisationWithApiUser}>({
            query: (params) => ({
                url: `/register`,
                method: 'POST',
                body: params.payload,
            }),
        }),
        getOrganisation: build.query<{data: IOrganisation}, { jwtToken: string }>({
            query: (params) => ({
                url: `/organisations`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getAdminOrganisations: build.query<{data: IOrganisation[]}, { jwtToken: string }>({
            query: (params) => ({
                url: `/organisations/admin`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            providesTags: (result) => [
                ...(result?.data || []).map(organisation => ({
                    type: EntityTag.Organisation,
                    id: organisation.id,
                })),
                {
                    type: EntityTag.Organisation,
                    id: 'LIST',
                },
            ],
        }),
        getAdminOrganisationFromId: build.query<{data: IOrganisation}, { jwtToken: string, organisationId: number }>({
            query: (params) => ({
                url: `/organisations/admin/${params.organisationId}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getOrganisationUsers: build.query<{data: IApiUser[]}, { jwtToken: string, organisationId: number }>({
            query: (params) => ({
                url: `/users/organisation-api-users/${params.organisationId}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        updateAccountCredit: build.mutation<IOrganisation, {jwtToken: string, organisationId: number, payload: IUpdateAccountCredit}>({
            query: (params) => ({
                url: `/organisations/account/credit/${params.organisationId}`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getAccountDetails: build.query<{data: IOrganisation}, { jwtToken: string }>({
            query: (params) => ({
                url: `/organisations/account`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            providesTags: [EntityTag.AccountDetails]
        }),
        checkAccount: build.mutation<IOrganisation, {jwtToken: string}>({
            query: (params) => ({
                url: `/organisations/account`,
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            invalidatesTags: [
                EntityTag.AccountDetails,
            ],
        }),
        getStripeCustomerSessionClientSecret: build.query<{data: IStripeCustomerSessionClientSecret}, { jwtToken: string }>({
            query: (params) => ({
                url: `/stripe/customerSession`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getStripeCustomerPortalSession: build.query<{data: IStripeCustomerPortalSession}, { jwtToken: string }>({
            query: (params) => ({
                url: `/stripe/customerPortalSession`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getGuidesProgress: build.query<{data: IGuidesProgress}, { jwtToken: string }>({
            query: (params) => ({
                url: `/users/guides-progress`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            providesTags: [EntityTag.GuidesProgress]
        }),
        updateGuidesProgress: build.mutation<IGuidesProgress, {jwtToken: string, payload: IGuidesProgress}>({
            query: (params) => ({
                url: `/users/guides-progress`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            invalidatesTags: [
                EntityTag.GuidesProgress,
            ],
        }),
    }),
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useUpdatePasswordMutation,
    useGetProfileMutation,
    useGetOrganisationQuery,
    useGetUsersQuery,
    useUpdateUserMutation,
    useCreateUserMutation,
    useCreateOrganisationWithUserMutation,
    useGetAdminOrganisationsQuery,
    useGetStripeCustomerSessionClientSecretQuery,
    useGetStripeCustomerPortalSessionQuery,
    useGetGuidesProgressQuery,
    useUpdateGuidesProgressMutation,
    useGetAccountDetailsQuery,
    useCheckAccountMutation,
    useGetAdminOrganisationFromIdQuery,
    useGetOrganisationUsersQuery,
    useUpdateAccountCreditMutation,
    } = api;

export default api;
