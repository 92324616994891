import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Card, Col, Layout, Modal, Row, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";
import {LockOutlined, SelectOutlined} from "@ant-design/icons";
import {useFeatureActive} from "../hooks/useFeatureActive";
import {FeatureTypeEnum} from "../enums/FeatureTypeEnum";
import {UpgradeModal} from "../components/UpgradeModal";

const { Content } = Layout;

const DashboardPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [accessToGettingStarted] = useFeatureActive(FeatureTypeEnum.AccessToGettingStarted);
    const [accessToWritingGuide] = useFeatureActive(FeatureTypeEnum.AccessToWritingGuide);
    const [accessToBookTemplate] = useFeatureActive(FeatureTypeEnum.AccessToBookTemplate);
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

    const onUpgradeClose = useCallback(() => {
        setShowUpgradeModal(false);
    }, []);

    const error: any = null;

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const writingGuideUrl = useMemo(() => {
        return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/template-writing-guide.pdf';
    }, []);

    const gettingStartedUrl = useMemo(() => {
        return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/getting-started.docx';
    }, []);

    const bookTemplateUrl = useMemo(() => {
        return 'https://rememberpress-public.s3.us-east-1.amazonaws.com/book-template.docx';
    }, []);

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation ? user.organisation.name : 'Platform'}</Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.dashboardName'} /></Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2 style={{textAlign: 'center'}}><FormattedMessage id={'dashboard.title'} /></h2>
                <p style={{textAlign: 'center'}}><FormattedMessage id={'dashboard.subTitle'} /></p>
                <Row gutter={16} align='middle' style={{paddingTop: '2rem'}}>
                    <Col span={3}>
                    </Col>
                    <Col span={6}>
                        <Card
                            style={{paddingTop: '1rem'}}
                            hoverable
                            cover={<img alt="example" src="/getting-started.png" style={{objectFit: 'contain', height: '30rem', border: '0.7rem solid', borderRadius: '1rem'}}/>}
                            onClick={() => accessToGettingStarted ? window.open(gettingStartedUrl) : setShowUpgradeModal(true)}
                        >
                            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                Getting started {accessToGettingStarted ? <SelectOutlined rotate={90} /> : <LockOutlined />}
                            </div>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            style={{paddingTop: '1rem'}}
                            hoverable
                            cover={<img alt="example" src="/writing-guide.png" style={{objectFit: 'contain', height: '30rem', border: '0.7rem solid', borderRadius: '1rem'}}/>}
                            onClick={() => accessToGettingStarted ? window.open(writingGuideUrl) : setShowUpgradeModal(true)}
                        >
                            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                Writing guide {accessToWritingGuide ? <SelectOutlined rotate={90} /> : <LockOutlined />}
                            </div>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            style={{paddingTop: '1rem'}}
                            hoverable
                            cover={<img alt="example" src="/book-template.png" style={{objectFit: 'contain', height: '30rem', border: '0.7rem solid', borderRadius: '1rem'}}/>}
                            onClick={() => accessToGettingStarted ? window.open(bookTemplateUrl) : setShowUpgradeModal(true)}
                        >
                            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                Book template {accessToBookTemplate ? <SelectOutlined rotate={90} /> : <LockOutlined />}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                open={showUpgradeModal}
                onCancel={onUpgradeClose}
                centered
                footer={null}
                width={'60rem'}
                className='upgrade-modal'
                destroyOnClose={false}
            >
                <UpgradeModal
                    onclose={onUpgradeClose}
                />
            </Modal>
        </Content>
    );
}

export default DashboardPage;
