import React, {useCallback, useMemo} from "react";
import {Button, Card, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {DeleteOutlined} from "@ant-design/icons";
import {IOrganisation} from "../../services/nestjs-base-api/types";
import {useAuth} from "../../hooks/useAuth";

interface IProps {
    organisation: IOrganisation;
}
const OrganisationCard: React.FunctionComponent<IProps> = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { user } = useAuth();

    const onCardClick = useCallback(() => {
        navigate(`/dashboard/administration/organisations/${props.organisation.id}`, {replace: false});
    }, [navigate, props.organisation.id]);

    const title = useMemo(() => {
        if (props.organisation) {
            return `${props.organisation.name} - ${props.organisation.plan}`
        }

        return null;
    }, [props]);

    return (
        <Card className='organisation-card' title={title} onClick={onCardClick} >
            <div className="organisation-card-footer">
                <div className="organisation-card-footer-left">

                </div>
                <div className="organisation-card-footer-right">
                    <Space>
                        <Button
                            className='organisation-card-delete'
                            icon={<DeleteOutlined />}
                            title={intl.formatMessage({ id: 'common.deleteButton' })}
                        />
                    </Space>
                </div>
            </div>
        </Card>
    );
}

export default OrganisationCard;
