export enum FeatureTypeEnum {
  AccessToGuides = 'AccessToGuides',
  AccessToGettingStarted = 'AccessToGettingStarted',
  AccessToWritingGuide = 'AccessToWritingGuide',
  AccessToBookTemplate = 'AccessToBookTemplate',
  AccessToImagesLibrary = 'AccessToImagesLibrary',
  WriteAccessToImagesLibrary = 'WriteAccessToImagesLibrary',
  AccessToBooksLibrary = 'AccessToBooksLibrary',
  WriteAccessToBooksLibrary = 'WriteAccessToBooksLibrary',
}
