import React from "react";
import {IOrganisation} from "../../services/nestjs-base-api/types";

interface IProps {
    organisation: IOrganisation;
}

const AdminOrganisationDetails: React.FunctionComponent<IProps> = (props) => {

    return (
        <div className="project-tab">
            {props.organisation && (
                <div>Details</div>
            )}
        </div>
    );
}

export default AdminOrganisationDetails;
