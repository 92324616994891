import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Card, Col, Layout, Modal, Progress, Row, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";
import {useGetGuidesProgressQuery} from "../services/nestjs-base-api";
import {IGuidesProgress} from "../services/nestjs-base-api/types";
import {useFeatureActive} from "../hooks/useFeatureActive";
import {FeatureTypeEnum} from "../enums/FeatureTypeEnum";
import {UpgradeModal} from "../components/UpgradeModal";
import {useNavigate} from "react-router-dom";
import {GuideTypeEnum} from "../enums/GuideTypeEnum";

const { Content } = Layout;

const GuidesPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [accessToGuidesActive] = useFeatureActive(FeatureTypeEnum.AccessToGuides);
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const {data: guidesProgressData, error: guidesProgressError} = useGetGuidesProgressQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});

    useEffect(() => {
        if (guidesProgressError) {
            if ('status' in guidesProgressError) {
                if (guidesProgressError.status === 401) {
                    logout();
                }
            }
        }
    }, [guidesProgressError, logout]);

    const guidesProgress = useMemo(() => {
        return guidesProgressData ? guidesProgressData as unknown as IGuidesProgress : undefined;
    }, [guidesProgressData]);

    const onUpgradeClose = useCallback(() => {
        setShowUpgradeModal(false);
    }, []);

    useEffect(() => {
        if (!accessToGuidesActive) {
            setShowUpgradeModal(true);
        }
    }, [accessToGuidesActive]);

    const workbookProgress = (guideType: GuideTypeEnum) => {
        const guide = guidesProgress?.guides.find((elem) => elem.guide === guideType);

        if (guide) {
            return guide.progress * 100;
        }

        return 0;
    };

    const guidesSection = useMemo(() => {
        if (accessToGuidesActive) {
            return (
                <>
                    <Row gutter={16} style={{padding: '1rem'}}>
                        <Col span={8}>
                            <Card
                                hoverable
                                style={{padding: '2rem 6rem', width: '100%'}}
                                cover={<img alt="example" src="/workbook-1.png"/>}
                                onClick={() => navigate("/dashboard/guides/workbook/1", { replace: false })}
                            >
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    Work Book 1
                                </div>
                                <Progress percent={workbookProgress(GuideTypeEnum.Workbook1)} showInfo={false} strokeColor="#ff8674" />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                hoverable
                                style={{ padding: '2rem 6rem', width: '100%' }}
                                cover={<img alt="example" src="/workbook-2.png" />}
                                onClick={() => navigate("/dashboard/guides/workbook/2", { replace: false })}
                            >
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    Work Book 2
                                </div>
                                <Progress percent={workbookProgress(GuideTypeEnum.Workbook2)} showInfo={false} strokeColor="#ff8674" />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                hoverable
                                style={{padding: '2rem 6rem', width: '100%'}}
                                cover={<img alt="example" src="/workbook-3.png"/>}
                                onClick={() => navigate("/dashboard/guides/workbook/3", { replace: false })}
                            >
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    Work Book 3
                                </div>
                                <Progress percent={workbookProgress(GuideTypeEnum.Workbook3)} showInfo={false} strokeColor="#ff8674" />
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{padding: '1rem'}}>
                        <Col span={8}>
                            <Card
                                hoverable
                                style={{padding: '2rem 6rem', width: '100%'}}
                                cover={<img alt="example" src="/workbook-4.png"/>}
                                onClick={() => navigate("/dashboard/guides/workbook/4", { replace: false })}
                            >
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    Work Book 4
                                </div>
                                <Progress percent={workbookProgress(GuideTypeEnum.Workbook4)} showInfo={false} strokeColor="#ff8674" />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                hoverable
                                style={{ padding: '2rem 6rem', width: '100%' }}
                                cover={<img alt="example" src="/workbook-5.png" />}
                                onClick={() => navigate("/dashboard/guides/workbook/5", { replace: false })}
                            >
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    Work Book 5
                                </div>
                                <Progress percent={workbookProgress(GuideTypeEnum.Workbook5)} showInfo={false} strokeColor="#ff8674" />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                hoverable
                                style={{padding: '2rem 6rem', width: '100%'}}
                                cover={<img alt="example" src="/workbook-6.png"/>}
                                onClick={() => navigate("/dashboard/guides/workbook/6", { replace: false })}
                            >
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    Work Book 6
                                </div>
                                <Progress percent={workbookProgress(GuideTypeEnum.Workbook6)} showInfo={false} strokeColor="#ff8674" />
                            </Card>
                        </Col>
                    </Row>
                </>
            );
        }

        return null;
    }, [accessToGuidesActive, navigate])

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation ? user.organisation.name : 'Platform'}</Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.guidesName'} /></Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2><FormattedMessage id={'section.guidesName'} /></h2>
                {guidesSection}
            </div>
            <Modal
                open={showUpgradeModal}
                onCancel={onUpgradeClose}
                centered
                footer={null}
                width={'60rem'}
                className='upgrade-modal'
            >
                <UpgradeModal
                    onclose={onUpgradeClose}
                />
            </Modal>
        </Content>
    );
}

export default GuidesPage;