import React, {useEffect, useMemo} from "react";
import {Breadcrumb, Layout, Tabs, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {IOrganisation} from "../services/nestjs-base-api/types";
import {FormattedMessage, useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {useGetAdminOrganisationFromIdQuery} from "../services/nestjs-base-api";
import AdminOrganisationDetails from "../components/Administration/AdminOrganisationDetails";
import AdminOrganisationUsers from "../components/Administration/AdminOrganisationUsers";
import AdminOrganisationAccount from "../components/Administration/AdminOrganisationAccount";

const { Content } = Layout;

interface IProps {
}

const OrganisationPage: React.FunctionComponent<IProps> = (props) => {
    const { organisationId } = useParams();
    const intl = useIntl();
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const {data, refetch, error} = useGetAdminOrganisationFromIdQuery({jwtToken: user.jwtToken, organisationId: +organisationId!}, {pollingInterval: 600000});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const organisation = useMemo(() => {
        return data ? data as unknown as IOrganisation : undefined;
    }, [data]);

    const detailsTab = <AdminOrganisationDetails organisation={organisation!} />;
    const usersTab = <AdminOrganisationUsers organisationId={+organisationId!} />;
    const accountTab = <AdminOrganisationAccount organisation={organisation!} refresh={refetch}/>;

    const tabs = [ {
            label: 'Details',
            key: 'details',
            children: detailsTab,
        }, {
            label: 'Users',
            key: 'users',
            children: usersTab,
        }, {
            label: 'Account',
            key: 'account',
            children: accountTab,
        },
    ];

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item><FormattedMessage id={'common.platformName'} /></Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.administrationName'} /></Breadcrumb.Item>
                <Breadcrumb.Item>{organisation ? organisation.name : intl.formatMessage({ id: 'common.platformName' })}</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2>{organisation?.name} - {organisation?.plan}</h2>
                <Tabs
                    defaultActiveKey="details"
                    type="card"
                    size="middle"
                    items={tabs}
                />
            </div>
        </Content>
    );
}

export default OrganisationPage;
