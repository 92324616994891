import React from "react";
import {Breadcrumb, Layout, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";

const { Content } = Layout;

const ProfilePage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation ? user.organisation.name : 'Platform'}</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Profile</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2><FormattedMessage id={'section.profileName'} /></h2>
            </div>
        </Content>
    );
}

export default ProfilePage;
