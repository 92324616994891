import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IApiUser} from "../../services/nestjs-base-api/types";
import {Button, Modal, Table} from "antd";
import {Utils} from "../../utils/utils";
import {useAuth} from "../../hooks/useAuth";
import {useGetOrganisationUsersQuery} from "../../services/nestjs-base-api";
import {UserRoleEnum} from "../../enums/UserRoleEnum";
import OrganisationUserUpdate from "../Organisation/OrganisationUserUpdate";
import OrganisationUserCreate from "../Organisation/OrganisationUserCreate";

interface IProps {
    organisationId?: number;
}

const AdminOrganisationUsers: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const [apiUser, setApiUser] = useState<IApiUser | undefined>(undefined);
    const [createApiUserOpen, setCreateApiUserOpen] = useState<boolean>(false);
    const {data, refetch, error} = useGetOrganisationUsersQuery({jwtToken: user.jwtToken, organisationId: props.organisationId!}, {pollingInterval: 600000})

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const apiUsers = useMemo(() => {
        return data ? data as unknown as IApiUser[] : undefined;
    }, [data]);

    const onUpdateClose = useCallback(() => {
        setApiUser(undefined);
        refetch();
    }, [refetch]);

    const onCreateClose = useCallback(() => {
        setCreateApiUserOpen(false);
        refetch();
    }, [refetch]);

    const onEditClicked = useCallback((id: number) => {
        setApiUser(apiUsers?.find((user) => user.id === id));
    }, [apiUsers]);

    const onCreateClicked = useCallback(() => {
        setCreateApiUserOpen(true);
    }, []);

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        }, {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            render: ((_: string, row: IApiUser) => {
                const roles = JSON.parse(row.roles);

                let returnValue = '';
                let first = true;
                roles.forEach((role: UserRoleEnum) => {
                    if (!first) {
                        returnValue += ", ";
                    }

                    returnValue += Utils.readableUserRole(role);
                    first = false;
                });
                return returnValue;
            })
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: ((_: string, row: IApiUser) => {
                return Utils.readableGeneralStatus(row.status);
            })
        }, {
            title: 'Actions',
            key: 'actions',
            render: ((_: string, row: IApiUser) => {
                return (
                  <Button onClick={() => onEditClicked(row.id)} >
                    Edit
                  </Button>
                );
            })
        }
    ];

    return (
        <div className="project-tab">
            <Button type='primary' style={{marginBottom: '1rem'}} onClick={onCreateClicked}>
                Add user
            </Button>
            <Table dataSource={apiUsers} columns={columns}/>
            <Modal
                open={!!apiUser}
                onCancel={onUpdateClose}
                centered
                footer={null}
            >
                <OrganisationUserUpdate
                    apiUser={apiUser}
                    onClose={onUpdateClose}
                />

            </Modal>
            <Modal
                open={createApiUserOpen}
                onCancel={onCreateClose}
                centered
                footer={null}
            >
                <OrganisationUserCreate
                    onClose={onCreateClose}
                />
            </Modal>
        </div>
    );
}

export default AdminOrganisationUsers;
