import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import SigninPage from "./pages/SigninPage";
import DashboardPage from "./pages/DashboardPage";
import {UnprotectedLayout} from "./components/UnprotectedLayout";
import {ProtectedLayout} from "./components/ProtectedLayout";
import {AuthProvider} from "./hooks/useAuth";
import ProfilePage from "./pages/ProfilePage";
import SignupPage from "./pages/SignupPage";
import ForgotPage from "./pages/ForgotPage";
import {Provider} from "react-redux";
import {store} from "./store";
import { ConfigProvider } from 'antd';
import OrganisationPage from "./pages/OrganisationPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import PasswordSetPage from "./pages/PasswordSetPage";
import {IntlProvider} from "react-intl";
import localeEn from "./locale/en";
import AccountPage from "./pages/AccountPage";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import AddonsPage from "./pages/AddonsPage";
import GuidesPage from "./pages/GuidesPage";
import WorkbookPage from "./pages/WorkbookPage";
import BooksPage from "./pages/BooksPage";
import ImagesPage from "./pages/ImagesPage";
import AdminPage from "./pages/AdminPage";
import AdminOrganisationPage from "./pages/AdminOrganisationPage";

function App() {
  return (
      <ConfigProvider
          theme={{
              token: {
                  // Seed Token
                  colorPrimary: '#ff8674',
                  colorTextBase: '#0f172a',
                  fontSize: 18,
                  colorLink: 'rgb(255, 130, 92)',
                  borderRadius: 2,
                  colorBgContainer: '#FEF9F1',

                  // Alias Token
              },
          }}
      >
          <Provider store={store}>
              <IntlProvider
                  messages={localeEn.messages}
                  locale={localeEn.code}
              >
                  <AuthProvider>
                      <Routes>
                          <Route element={<UnprotectedLayout />}>
                              <Route path="/" element={<HomePage />} />
                              <Route path="/sign-in" element={<SigninPage />} />
                              <Route path="/forgot" element={<ForgotPage />} />
                              <Route path="/sign-up" element={<SignupPage />} />
                              <Route path="/password-reset/:resetToken" element={<PasswordResetPage />} />
                              <Route path="/password-set/:resetToken" element={<PasswordSetPage />} />
                          </Route>

                          <Route path="/dashboard" element={<ProtectedLayout />}>
                              <Route path="" element={<DashboardPage />} />
                              <Route path="profile" element={<ProfilePage />} />
                              <Route path="settings" element={<OrganisationPage />} />
                              <Route path="account" element={<AccountPage />} />
                              <Route path="subscriptions" element={<SubscriptionsPage />} />
                              <Route path="addons" element={<AddonsPage />} />
                              <Route path="guides/workbook/:bookNumber" element={<WorkbookPage />} />
                              <Route path="guides" element={<GuidesPage />} />
                              <Route path="books" element={<BooksPage />} />
                              <Route path="images" element={<ImagesPage />} />
                              <Route path="administration/organisations/:organisationId" element={<AdminOrganisationPage />} />
                              <Route path="administration" element={<AdminPage />} />
                          </Route>
                      </Routes>
                  </AuthProvider>
              </IntlProvider>
          </Provider>
      </ConfigProvider>
  );
}

// @ts-ignore
export default App;
