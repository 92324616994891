import {FeatureTypeEnum} from "../enums/FeatureTypeEnum";
import {useAuth} from "./useAuth";
import {FeatureStatusEnum} from "../enums/FeatureStatusEnum";
import {IOrganisationFeatures} from "../services/nestjs-base-api/types";

export const useFeatureActive = (featureToCheck: FeatureTypeEnum) => {
    const { user } = useAuth();

    let returnValue = false;

    if (user.organisation.organisationFeaturesDto) {
        const organisationFeatures: IOrganisationFeatures = JSON.parse(user.organisation.organisationFeaturesDto);

        const featureInQuestion = organisationFeatures.features.find((feature: any) => feature.name === featureToCheck);

        if (featureInQuestion && featureInQuestion.specification.status === FeatureStatusEnum.Active) {
            returnValue = true;
        }
    }

    return [returnValue];
};
