import {Layout, theme} from "antd";
import React, { Suspense } from "react";
import {Navigate, useOutlet} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {Utils} from "../utils/utils";

const { Header, Footer } = Layout;

export const UnprotectedLayout = () => {
    const { user } = useAuth();
    const outlet = useOutlet();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    if (user) {
        if (Utils.userIsSuperAdmin(user)) {
            return <Navigate to="/dashboard/administration" />;
        }
        return <Navigate to="/dashboard" />;
    }

    return (
        <Suspense fallback={null}>
            <Layout style={{ minHeight: '100vh' }}>
                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer }} />
                    {outlet}
                    <Footer style={{ textAlign: 'center' }}>
                        RememberPress.com © {new Date().getFullYear()} by Syriant
                    </Footer>
                </Layout>
            </Layout>
        </Suspense>
    )
};
