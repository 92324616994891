import {GeneralStatusEnum} from "../../enums/GeneralStatusEnum";
import {PlanLevelEnum} from "../../enums/PlanLevelEnum";
import {FeatureTypeEnum} from "../../enums/FeatureTypeEnum";
import {FeatureStatusEnum} from "../../enums/FeatureStatusEnum";
import {CreditStatusEnum} from "../../enums/CreditStatusEnum";

enum EntityTag {
    Organisation = 'ORGANISATION',
    GuidesProgress = 'GUIDES_PROGRESS',
    AccountDetails = 'ACCOUNT_DETAILS',
}

interface IAuth {
    access_token: string;
}

interface IOrganisation {
    id: number;
    name: string;
    organisationFeaturesDto?: string;
    plan: PlanLevelEnum;
    organisationCreditsDto?: string;
    organisationInvoicesDto?: string;
    organisationSubscriptionsDto?: string;
}

interface IApiUser {
    id: number;
    email: string;
    roles: string;
    status: GeneralStatusEnum;
    organisation: IOrganisation;
}

interface IUpdateApiUser {
    id: number;
    email: string;
    roles: string;
    status: GeneralStatusEnum;
}

interface ICreateApiUser {
    email: string;
    roles: string;
}

interface ICreateOrganisationWithApiUser {
    organisationName: string;
    firstName: string;
    lastName: string;
    email: string;
}

interface IFeatureSpecification {
    status: FeatureStatusEnum;
    value: any;
    unlockLevel: PlanLevelEnum;
    expires?: Date;
}

interface IFeature {
    name: FeatureTypeEnum,
    specification: IFeatureSpecification;
}

interface IOrganisationFeatures {
    features: IFeature[];
}

interface IStripeCustomerSessionClientSecret {
    secret: string;
}

interface IStripeCustomerPortalSession {
    url: string;
}

interface IProgress {
    guide: string;
    progress: number;
}

interface IGuidesProgress {
    guides: IProgress[];
}

interface IUpdateAccountCredit {
    status: CreditStatusEnum;
    paymentIdent: string;
    used: number;
    available: number;
    total: number;
}

export {
    EntityTag,
};

export type {
    IAuth,
    IApiUser,
    IUpdateApiUser,
    ICreateApiUser,
    ICreateOrganisationWithApiUser,
    IOrganisation,
    IFeatureSpecification,
    IFeature,
    IOrganisationFeatures,
    IStripeCustomerSessionClientSecret,
    IStripeCustomerPortalSession,
    IProgress,
    IGuidesProgress,
    IUpdateAccountCredit,
}
