import React, {useEffect, useMemo} from "react";
import {Breadcrumb, Layout, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";
import {useGetStripeCustomerSessionClientSecretQuery} from "../services/nestjs-base-api";
import {IStripeCustomerSessionClientSecret} from "../services/nestjs-base-api/types";
import {Utils} from "../utils/utils";

const { Content } = Layout;

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const SubscriptionsPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const {data, error} = useGetStripeCustomerSessionClientSecretQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const stripeSessionClientSecret = useMemo(() => {
        return data ? data as unknown as IStripeCustomerSessionClientSecret : undefined;
    }, [data]);

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation ? user.organisation.name : 'Platform'}</Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.pricingName'} /></Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2><FormattedMessage id={'section.pricingName'} /></h2>
                {stripeSessionClientSecret && (
                    <stripe-pricing-table className='stripe-pricing-table'
                                          pricing-table-id={Utils.getSubscriptionPricingTableId()}
                                          publishable-key={Utils.getPublishableKey()}
                                          customer-session-client-secret={stripeSessionClientSecret.secret}
                    >
                    </stripe-pricing-table>
                )}
            </div>
        </Content>
    );
}

export default SubscriptionsPage;
