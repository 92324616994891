import React, {useEffect, useMemo, useState} from "react";
import {
    IUpdateAccountCredit
} from "../../services/nestjs-base-api/types";
import {Button, Form, FormProps, InputNumber, message, Select} from "antd";
import {Utils} from "../../utils/utils";
import {useUpdateAccountCreditMutation} from "../../services/nestjs-base-api";
import {useAuth} from "../../hooks/useAuth";
import {CreditStatusEnum} from "../../enums/CreditStatusEnum";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    organisationId: number;
    accountCredit: any;
    onClose: () => void;
}

type FieldType = {
    used: number;
    available: number;
    total: number;
    status: string;
};

const AdminOrganisationAccountCreditUpdate: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [updateAccountCredit, updateAccountCreditResult] = useUpdateAccountCreditMutation();
    const { user, logout } = useAuth();

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = async(values) => {
        const payload = {
            paymentIdent: props.accountCredit.paymentIdent,
            used: values.used,
            available: values.available,
            total: values.total,
            status: values.status,
        } as IUpdateAccountCredit;

        const updateResponse = await updateAccountCredit({jwtToken: user.jwtToken, organisationId: props.organisationId, payload: payload});

        if ('data' in updateResponse) {
            message.success(`'${payload.paymentIdent}' updated`);
            props.onClose();
        } else {
            message.error(`Uh oh! Looks like the update of '${payload.paymentIdent}' failed :(`)
        }
    };

    const statusKeys = useMemo(() => {
        return Object.values(CreditStatusEnum);
    }, []);

    useEffect(() => {
        form.setFieldValue("used", props.accountCredit.used);
        form.setFieldValue("available", props.accountCredit.available);
        form.setFieldValue("total", props.accountCredit.total);
        form.setFieldValue("status", props.accountCredit.status);
    }, [props, form]);

    return (
        <>
            <h2>Update {props.accountCredit.paymentIdent}'s credit</h2>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="user-update-form"
                className="user-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="Used" name="used" rules={[{ required: true, type: 'number', message: 'Please enter a value'}]}>
                    <InputNumber min={0} max={9999}/>
                </Form.Item>
                <Form.Item<FieldType> label="Available" name="available" rules={[{ required: true, type: 'number', message: 'Please enter a value'}]}>
                    <InputNumber min={0} max={9999}/>
                </Form.Item>
                <Form.Item<FieldType> label="Total" name="total" rules={[{ required: true, type: 'number', message: 'Please enter a value'}]}>
                    <InputNumber min={0} max={9999}/>
                </Form.Item>
                <Form.Item<FieldType> label="Status" name="status" rules={[{ required: true, message: 'You have to specify a status', type: 'string'}]}>
                    <Select placeholder="Select status">
                        {statusKeys.map((status, index) => (
                                <Option key={index} value={status}>{`${Utils.readableCreditStatus(status as CreditStatusEnum)}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={updateAccountCreditResult.isLoading} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={updateAccountCreditResult.isLoading} style={{marginLeft: '1rem'}} type="default" onClick={props.onClose} >Cancel</Button>
                </Form.Item>
                <div>{JSON.stringify(props.accountCredit)}</div>
            </Form>
        </>
    );
}

export default AdminOrganisationAccountCreditUpdate;
