import React, {useEffect, useMemo} from "react";
import {Breadcrumb, Col, Layout, Row, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import {FormattedMessage} from "react-intl";
import {useGetAdminOrganisationsQuery} from "../services/nestjs-base-api";
import {IOrganisation} from "../services/nestjs-base-api/types";
import OrganisationCard from "../components/Organisation/OrganisationCard";

const { Content } = Layout;

const AdminPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const {data, error} = useGetAdminOrganisationsQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const organisations = useMemo(() => {
        return data ? data as unknown as IOrganisation[] : [];
    }, [data]);

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item><FormattedMessage id={'common.platformName'} /></Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'section.administrationName'} /></Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2 style={{textAlign: 'center'}}><FormattedMessage id={'common.platformName'} /> <FormattedMessage id={'section.administrationName'} /></h2>
                <Row gutter={16} align='middle' style={{paddingTop: '2rem'}}>
                    {organisations.map((organisation: IOrganisation) =>
                        <Col key={organisation.id} xs={24} sm={12} md={6} lg={6} xl={6} className="dashboard-column">
                            <OrganisationCard organisation={organisation} />
                        </Col>
                    )}
                </Row>
            </div>
        </Content>
    );
}

export default AdminPage;
